body{
    font-family: "Baloo Bhaijaan 2", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}

.btn{
    background: #C40505;
}

.two-lines {
    display: -webkit-box;       /* Display as a flexible box */
    -webkit-box-orient: vertical; /* Box orientation set to vertical */
    overflow: hidden;            /* Hide any overflowing content */
    text-overflow: ellipsis;     /* Show ellipsis (...) for overflow */
    -webkit-line-clamp: 2;       /* Limit to 2 lines */
  }