
.img-product {
  position: relative;
  /* Add other styles as needed */
}

.img-product img {
  width: 100%;
  /* Add other styles as needed */
}

.product-icon-action {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
  /* Hide the icons by default */
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.product-icon-action a {
  width: 46px;
  height: 46px;
  line-height: 46px;
  background-color: #fff;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.08);
  border-radius: 50%;
  margin-left: 10px;
  margin-right: 10px;
  color: #111111;
  display: block;
  margin-bottom: 13px;
  transition: 0.5s;
  font-size: 0;
}
a:hover {
  cursor: pointer;
}

/* Show icons on hover */
.product-item-v9:hover .product-icon-action {
  /* transform: translateX(-50%) translateY(0); */
  /* opacity: 1;
  visibility: visible; */
}
/* .product-item-v9 .product-icon-action {
  position: absolute;
  left: 50%;
  bottom: 10%;
  transform: translateX(-50%) translateY(15px);
  display: flex;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
} */

.thumb {
  width: 100%;
  display: flex;
  cursor: pointer;
  margin: 10px 0;
  justify-content: center;
  overflow: auto;
}
.thumb img {
  width: 65px;
  height: 100%;
  display: block;
  object-fit: cover;
  border: 1px solid #ddd;
  margin-right: 5px;
  opacity: 0.7;
  border-radius: 5px;
}
.thumb img.active {
  opacity: 1;
  border: 1px solid lightseagreen;
}
